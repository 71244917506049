@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "hkgrotesk";
  src: local("hkgrotesk light"),
    url("../../fonts/hkgrotesk-light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "hkgrotesk";
  src: local("hkgrotesk regular"),
    url("../../fonts/hkgrotesk-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "hkgrotesk";
  src: local("hkgrotesk medium"),
    url("../../fonts/hkgrotesk-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "hkgrotesk";
  src: local("hkgrotesk semibold"),
    url("../../fonts/hkgrotesk-semibold.woff") format("woff");
  font-weight: 600;
}
